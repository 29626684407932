<template>
    <div>
        <infoHeader :content_name="'新增产品信息'" />

        <h4>输入需要新增的产品信息</h4>

        <el-form
            ref="formData"
            :model="formData"
            label-width="160px"
            :label-position="'left'"
        >
            <el-form-item label="产品类目">
                <el-select
                    v-model="formData.product_type_id"
                    placeholder="请选择"
                    @change="handleTypeChange"
                >
                    <el-option
                        v-for="item in value"
                        :key="item.id"
                        :label="item.product_type_name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="产品名">
                <el-input v-model="formData.product_name"></el-input>
            </el-form-item>

            <template v-if="typeData.content_type_code == 1">
                <el-form-item label="产品型号">
                    <el-input v-model="formData.product_series"></el-input>
                </el-form-item>
            </template>

            <el-form-item label="">
                <el-button type="primary" @click="submit()"
                    >确认无误，更新信息</el-button
                >
            </el-form-item>
        </el-form>

        <el-divider></el-divider>
    </div>
</template>

<script>
// 载入接口
// import { 接口函数 } from "@/api/接口函数定义文件";
import {
    product_classify_get,
    product_create
} from '@/api/manage/operation/product/product.js';

// 载入方法
// import { 方法名 } from "@/utils/js文件名";

export default {
    name: 'start',
    data() {
        return {
            formData: {
                product_name: null,
                product_series: null,
                product_code: undefined,
                product_type_id: null
            },
            typeData: {
                id: null,
                content_type: null,
                content_type_code: null,
                product_type_name: null
            },
            selectNowProductType: {},
            product_type_table_data: [],
            value: []
        };
    },
    components: {},
    created() {
        this.initOption();
    },
    methods: {
        submit() {
            product_create(this.formData).then(res => {
                if (res.code == 200) {
                    this.value = res.data;
                    this.$router.push({ name: 'product_Manage' });
                } else {
                    this.$message.info(res.msg);
                }
            });
        },
        initOption() {
            product_classify_get({}).then(res => {
                if (res.code == 200) {
                    this.value = res.data;
                } else {
                    this.$message.info(res.msg);
                }
            });
        },
        handleTypeChange(selectedItemId) {
            const selectedItem = this.value.find(
                item => item.id === selectedItemId
            );
            this.productionTypeChange(selectedItem);
        },
        productionTypeChange(item) {
            console.log(item);
            this.typeData.content_type_code = item.content_type_code;
        }
    }
};
</script>

<style lang="scss" scoped></style>
